export default [
    {
        path: '/gcs',
        component: () => import('./index.vue'),
        redirect: '/gcs/chart',
        children: [
            {
                path: 'area',
                meta: {title: '小区'},
                component: () => import('./area/list.vue'),
            },
            {
                path: 'device/list',
                meta: {title: '设备'},
                component: () => import('./device/list.vue'),
            },
            {
                path: 'device/online',
                meta: {title: '在线设备'},
                component: () => import('./device/online.vue'),
            },
            {
                path: 'card',
                meta: {title: '生态卡'},
                component: () => import('./card/list.vue'),
            },
            {
                path: 'user',
                meta: {title: '用户'},
                component: () => import('./user/list.vue'),
            },
            {
                path: 'category',
                meta: {title: '分类'},
                component: () => import('./category/list.vue'),
            },
            {
                path: 'category/chart',
                meta: {title: '分类统计'},
                component: () => import('./category/chart.vue'),
            },
            {
                path: 'order',
                meta: {title: '预约'},
                component: () => import('./order/list.vue'),
            },
            {
                path: 'cashout',
                meta: {title: '提现'},
                component: () => import('./cashout/list.vue'),
            },
            {
                path: 'put',
                meta: {title: '投递记录'},
                component: () => import('./put/list.vue'),
            },
            {
                path: 'redeem',
                meta: {title: '兑换记录'},
                component: () => import('./redeem/list.vue'),
            },
            {
                path: 'points',
                meta: {title: '积分', keepAlive: true},
                component: () => import('./points/list.vue'),
            },
            {
                path: 'event',
                meta: {title: '设备事件'},
                component: () => import('./event/index.vue'),
                redirect: '/gcs/event/status',
                children: [
                    {
                        path: 'status',
                        meta: {title: '设备状态'},
                        component: () => import('./event/list-status.vue'),
                    },
                    {
                        path: 'warn',
                        meta: {title: '设备报警'},
                        component: () => import('./event/list-warn.vue'),
                    },
                ]
            },
            {
                path: 'op/staff',
                meta: {title: '运营人员'},
                component: () => import('./op/staff/list.vue'),
            }
        ]
    },
    {
        path: '/gcs/wechat',
        component: () => import('./wechat/index.vue'),
        redirect: '/gcs/wechat/swiper',
        children: [
            {
                path: 'swiper',
                meta: {title: '轮播'},
                component: () => import('./wechat/swiper/index.vue'),
            },
            {
                path: 'post',
                meta: {title: '发布'},
                component: () => import('./wechat/post/list.vue'),
            },
            {
                path: 'post/create',
                meta: {title: '创建发布'},
                component: () => import('./wechat/post/create.vue'),
            },
            {
                path: 'post/edit/:id',
                meta: {title: '创建发布'},
                component: () => import('./wechat/post/create.vue'),
            },
            {
                path: 'feedback',
                meta: {title: '反馈'},
                component: () => import('./wechat/feedback/list.vue'),
            },
            {
                path: 'gzh',
                meta: {permit: 'super', title: '公众号用户'},
                component: () => import('./wechat/gzh/list.vue'),
            }
        ]
    },
    {
        path: '/gcs/device/map',
        meta: {title: '地图'},
        component: () => import('./device/index.vue'),
    },
    {
        path: '/gcs/post/show/:id',
        meta: {title: '新闻资讯', acl: 'disabled'},
        component: () => import('./wechat/post/show.vue'),
    },
    {
        path: '/gcs/post/link/:link',
        meta: {title: '新闻资讯', acl: 'disabled'},
        component: () => import('./wechat/post/show.vue'),
    },
    {
        path: '/gcs/post/phone/:id',
        meta: {title: '新闻资讯', acl: 'disabled'},
        component: () => import('./wechat/post/phone.vue'),
    },
    {
        path: '/gcs/screen',
        meta: {title: '大屏'},
        component: () => import('./screen/index.vue'),
    },
    {
        path: '/gcs/chart',
        meta: {title: '分析'},
        component: () => import('./chart/index.vue'),
    }
]
