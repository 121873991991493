import {loadJs} from "./require-file";

/**
 * @author xuqinjie
 * @date 2018/2/12
 */

function buildTree(root, nodes, idCol, pidCol, labelCol) {
    _.each(nodes, node => {
        if (node[pidCol] === root.id) {
            root.children.push({
                id: node[idCol],
                label: node[labelCol],
                children: [],
                raw: node
            })
        }
    });

    if (root.children.length > 0) {
        _.each(root.children, node => buildTree(node, nodes, idCol, pidCol, labelCol));
    }
}

export default {
    formatSize(size) {
        if (size >= 1024 * 1024 * 1024)
            return parseFloat(size / (1024 * 1024 * 1024)).toFixed(1) + 'G';
        if (size >= 1024 * 1024)
            return parseFloat(size / (1024 * 1024)).toFixed(1) + 'M';
        if (size >= 1024)
            return parseFloat(size / 1024).toFixed(1) + 'K';
        return size + 'B';
    },
    has(exts, ext) {
        for (let i = 0; i < exts.length; i++) {
            if (exts[i] === ext)
                return true;
        }
        return false;
    },
    isImage(ext) {
        return this.has(['png', 'jpg', 'jpeg', 'gif', 'bmp', 'ico'], ext);
    },
    isVideo(ext) {
        return this.has(['flv', 'f4v', 'mp4', 'mov', 'mkv', 'rmvb'], ext);
    },
    isDoc(ext) {
        return this.has(['doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'epub', 'fodt', 'mht', 'odt', 'rtf', 'djvu', 'xps'], ext);
    },
    isExcel(ext) {
        return this.has(['csv', 'fods', 'ods', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx'], ext);
    },
    isPPT(ext) {
        return this.has(['fodp', 'odp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx'], ext);
    },
    isCode(ext) {
        return this.has(['txt', 'php', 'config', 'conf', 'cpp', 'c', 'html', 'htm', 'md', 'js', 'css', 'java', 'bpmn', 'log', 'md'], ext);
    },
    isPdf(ext) {
        return this.has(['pdf'], ext);
    },
    uuid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    },
    // 删除构建用于treeselect中的children为空的情况
    clearTreeSelectNodes(nodes) {
        _.each(nodes, node => {
            if (node.children !== null && node.children !== undefined) {
                if (!node.children.length)
                    delete node.children;
                else
                    this.clearTreeSelectNodes(node.children);
            }
        });
        return nodes;
    },
    // 对字符串按照长度分割
    chunkString(str, size) {
        let numChunks = Math.ceil(str.length / size);
        if (isNaN(numChunks) || numChunks < 1)
            numChunks = 1;
        const chunks = new Array(numChunks);
        for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
            chunks[i] = str.substr(o, size)
        }
        return chunks
    },
    all() {
        return {current: 0, size: 65536};
    },

    buildTree(nodes, idCol, pidCol, labelCol) {
        let roots = [];
        _.each(nodes, node => {
            if (_.findIndex(nodes, n => n.id === node.pid) === -1)
                roots.push({
                    id: node[idCol],
                    label: node[labelCol],
                    children: [],
                    raw: node,
                });
        });

        _.each(roots, node => buildTree(node, nodes, idCol, pidCol, labelCol));
        return roots;
    },

    walkTree(nodes, cb) {
        _.each(nodes, node => {
            if (!_.isNil(node.children)) {
                if (cb(node) === true) {
                    if (node.children.length)
                        this.walkTree(node.children);
                }
            }
        });
        return nodes;
    },

    randomString(len){
        const chars = [..."abcdefghijkmnpqrstuvwxyz123456789"];
        return [...Array(len)].map(i => chars[Math.random() * chars.length | 0]).join``;
    },

    exportExcel() {
        return loadJs('table2excel', './static/lib/table2excel/table2excel.js').then(() => {
            const te = new window.Table2Excel();
            return Promise.resolve(te);
        })
    }
}
