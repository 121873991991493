/**
 * @author xuqinjie
 * @since 2018/2/12
 */
import Cookies from 'js-cookie'

const LocalStorage = {
    set(key, value) {
        localStorage.setItem(key, value);
    },
    get(key) {
        return localStorage.getItem(key);
    },
    remove(key) {
        localStorage.removeItem(key);
    }
};

const CookiesStorage = {
    set(key, value, ops = {}) {
        Cookies.set(key, value, ops);
    },
    get(key) {
        return Cookies.get(key);
    },
    remove(key, ops = {}) {
        Cookies.remove(key, ops);
    }
};

const AUTH_TOKEN = 'x-auth-token';

const AuthToken = {
    get() {
        return CookiesStorage.get(AUTH_TOKEN) || '';
    },
    set(token) {
        CookiesStorage.set(AUTH_TOKEN, token)
    },
    remove() {
        CookiesStorage.remove(AUTH_TOKEN)
    }
};

export {LocalStorage, CookiesStorage, AuthToken};
