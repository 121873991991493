import _ from 'lodash'
import Tools from '../util/tools'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import zh from 'dayjs/locale/zh-cn'


export default function initFilters(Vue) {
    dayjs.locale(zh);
    dayjs.extend(relativeTime);

    Vue.filter('ago', v => {
        return dayjs(v).fromNow()
    });

    Vue.filter('filesize', v => {
        return Tools.formatSize(v);
    });

    Vue.filter('date', (v, f) => {
        return dayjs(v).format(f);
    });

    Vue.filter('chunk', (v, arg1) => {
        if (_.isString(v)) {
            let arr = Tools.chunkString(v, arg1);
            return _.join(arr, '<br>');
        }
        return v;
    });

    Vue.filter("fixed", (v, acc) => {
        let num = parseFloat(v);
        if (isNaN(num))
            num = 0;
        let accuracy = parseInt(acc);
        if (isNaN(accuracy) || accuracy < 0 || accuracy > 10) {
            accuracy = 2;
        }
        return num.toFixed(accuracy);
    });

    Vue.filter('truncate', (v, len) => {
        if (_.isString(v) && v.length > len)
            return v.substring(0, len) + '...';
        return v;
    });

    Vue.filter('prop', (v, label) => {
        if (_.isArray(v)) {
            for (let i = 0; i < v.length; i++) {
                let item = v[i];
                if (item.label === label)
                    return item.value;
            }
        }
        return v;
    });
}
