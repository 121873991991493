/**
 * @author xuqinjie
 * @date 2018/2/12
 */

import store from "../core/store";
import {toast} from "./request";
import config from "../../config";

let files = [];

export function loadJs(id, path) {
    let v = '1.0';
    return loadStaticJsImpl(id, path, v);
}

export function loadRemoteJs(id, path) {
    return loadRemoteJsImpl(id, path)
    // return loadStaticJsImpl(id, path)
}
export function loadLibJs(id, path) {
    return loadLibJsImpl(id, path)
}

export function loadRemoteJsImpl(id, path, version) {
    let a = files[id];
    if (!_.isNil(a))
        return a;

    store.commit('loading', true);

    if (!_.isNil(version))
        path += '?__ver=' + version;

    let p = new Promise((resolve, reject) => {
        let script = document.createElement('script');
        script.id = id;
        script.src = path;
        script.async = true;
        script.onerror = function () {
            store.commit('loading', false);
            toast('文件加载失败:' + path);
            reject(id);
        };

        script.onload = function () {
            store.commit('loading', false);
            resolve(id);
        };

        document.body.appendChild(script);
    });

    files[id] = p;
    return p;
}


export function loadLibJsImpl(id, path, version) {
    let a = files[id];
    if (!_.isNil(a))
        return a;

    store.commit('loading', true);

    if (!_.isNil(version))
        path += '?__ver=' + version;

    let p = new Promise((resolve, reject) => {
        let script = document.createElement('script');
        script.id = id;
        script.src = path;
        script.async = true;
        script.onerror = function () {
            store.commit('loading', false);
            toast('文件加载失败:' + path);
            reject(id);
        };

        script.onload = function () {
            store.commit('loading', false);
            resolve(id);
        };

        document.body.appendChild(script);
    });

    files[id] = p;
    return p;
}
export function loadStaticJsImpl(id, path, version) {
    let a = files[id];
    if (!_.isNil(a))
        return a;

    store.commit('loading', true);

    if (config.STATIC_URL) {
        if (path.startsWith('./'))
            path = path.substring(2);
        path = config.STATIC_URL + '/' + path;
    }

    if (!_.isNil(version))
        path += '?__ver=' + version;

    let p = new Promise((resolve, reject) => {
        let script = document.createElement('script');
        script.id = id;
        script.src = path;
        script.async = true;
        script.onerror = function () {
            store.commit('loading', false);
            toast('文件加载失败:' + path);
            reject(id);
        };

        script.onload = function () {
            store.commit('loading', false);
            resolve(id);
        };

        document.body.appendChild(script);
    });

    files[id] = p;
    return p;
}

export function loadCss(id, path) {
    let v = '1.0';
    return loadStaticCssImpl(id, path, v);
}

export function loadRemoteCss(id, path) {
    return loadStaticCssImpl(id, path);
}

export function loadStaticCssImpl(id, path, version) {
    let a = files[id];
    if (!_.isNil2(a))
        return a;

    store.commit('loading', true);

    if (config.STATIC_URL) {
        if (path.startsWith('./'))
            path = path.substring(2);
        path = config.STATIC_URL + '/' + path;
    }
    
    if (!_.isNil(version))
        path += '?__ver=' + version;

    let p = new Promise((resolve, reject) => {
        let head = document.getElementsByTagName('head')[0];
        let link = document.createElement('link');
        link.id = id;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = path;

        link.onerror = function () {
            store.commit('loading', false);
            toast('文件加载失败:' + path);
            reject(id);
        };

        link.onload = function () {
            store.commit('loading', false);
            resolve(id);
        };

        head.appendChild(link);
    });

    files[id] = p;
    return p;
}

function _removeOne(id) {
    let el = document.getElementById(id);
    if (!_.isNil(el)) {
        el.parentElement.removeChild(el);
        files[id] = null;
    }
}

export function removeFile(ids) {
    if (_.isArray(ids))
        _.each(ids, (id) => {
            _removeOne(id)
        });
    else
        _removeOne(ids);

}

