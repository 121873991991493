export default [
    {
        path: '/hq',
        component: () => import('./index.vue'),
        children: [
            {
                path: 'help',
                meta: {title: '帮助文档'},
                component: () => import('./help.vue'),
            },
            {
                path: 'feedback',
                meta: {title: '功能反馈'},
                component: () => import('./feedback.vue'),
            }
        ]
    }
]
