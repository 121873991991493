import {userSession} from "./api";
import {AuthToken} from "../common/util/storage";
import Vue from 'vue'

export default class Interceptor {
    constructor(router, store) {
        this.$router = router;
        this.$store = store;
    }

    init() {
        this.$router.beforeEach((to, from, next) => {
            if (!to.matched.length)
                return next('/404');

            if (to.meta.acl === 'disabled')
                return next();

            if (_.isNil(this.$store.state.app.user)) {
                const toLogin = '/login?return=' + to.fullPath;
                if (AuthToken.get()) {
                    userSession().then(map => {
                        if (_.isNil(map.user)) {
                            return next(toLogin);
                        } else {
                            this.$store.dispatch('updateSession', map);
                            return this.permit(to, next);
                        }
                    })
                } else
                    return next(toLogin);
            } else {
                return this.permit(to, next);
            }
        });

        this.$router.afterEach((to, from) => {
            if (!_.isNil(to.meta.title))
                document.title = to.meta.title + ' - ' + this.$store.state.app.appName;
            else
                document.title = this.$store.state.app.appName;
        })
    }

    permit(to, next) {
        if (to.meta.permit && !Vue.prototype.$permit(to.meta.permit))
            return next('/401');
        return next();
    }
}
