export default [
    {
        path: '/mms',
        redirect: '/mms/chart',
        component: () => import('./index.vue'),
        children: [
            {
                path: 'depot',
                meta: {title: '仓库'},
                component: () => import('./depot/list.vue'),
            },
            {
                path: 'vendor',
                meta: {title: '供应商'},
                component: () => import('./vendor/list.vue'),
            },
            {
                path: 'category',
                meta: {title: '分类'},
                component: () => import('./category/list.vue'),
            },
            {
                path: 'product',
                meta: {title: '商品'},
                component: () => import('./product/list.vue'),
            },
            {
                path: 'inbound',
                meta: {title: '入库单'},
                component: () => import('./inbound/list.vue'),
            },
            {
                path: 'outbound',
                meta: {title: '出库单'},
                component: () => import('./outbound/list.vue'),
            }
        ]
    },
    {
        path: '/mms/chart',
        meta: {title: '图表'},
        component: () => import('./chart/index.vue'),
    }
]
