import ElementUI from 'element-ui'
import Vue from 'vue'
import initFilters from './filter'

import httpVueLoader from '@/common/util/loader'
import config from '@/config'
import {AuthToken} from '@/common/util/storage'

function fuzzyMatch(permissions, key) {
    // 模糊匹配
    if (key.endsWith('*')) {
        let key2 = _.replace(key, '*', '');
        for (let i = 0; i < permissions.length; i++) {
            if (permissions[i].startsWith(key2))
                return true;
        }
    }

    return permissions.indexOf(key) !== -1
}

export default function init(store) {
    _.isNil2 = function (v) {
        return (v === null || v === undefined || v === 'undefined' || v === '' || v === 'null')
    };

    _.swap = function (arr, idx1, idx2) {
        arr[idx1] = arr.splice(idx2, 1, arr[idx1])[0];
        return arr;
    };

    _.move = function (arr, from, to) {
        arr.splice(to, 0, arr.splice(from, 1)[0]);
    };

    /**
     * key有多种数据类型
     * 1. 数组：表示判断一系列的子菜单，其中子元素可以是字符串也可以是子菜单对象
     * 2. 以*号结尾的字符串，表示模糊匹配，用于模块（倒数第二个字符必须为冒号)
     * 3. 其他字符串，表示严格匹配
     * @param key
     * @returns {boolean}
     */
    Vue.prototype.$permit = function (key) {
        if (_.isNil2(key) || store.state.app.admin)
            return true;

        let permissions = store.state.app.permissions;
        if (_.isNil(permissions))
            return false;

        // 如果所有的子菜单都无权限的话，那么父菜单也无权限
        if (_.isArray(key)) {
            for (let i = 0; i < key.length; i++) {
                if (_.isObject(key[i])) {
                    if (fuzzyMatch(permissions, key[i].permit))
                        return true;
                } else {
                    if (fuzzyMatch(permissions, key[i]))
                        return true;
                }
            }
            return false;
        }

        return fuzzyMatch(permissions, key);
    };

    Vue.prototype.$confirm = function (message, title, options = {type: 'warning', lockScroll: false}) {
        return ElementUI.MessageBox.confirm(message, title, options)
    };

    Vue.prototype.$prompt = function (message, v = null, title = null) {
        return new Promise((resolve) => {
            return ElementUI.MessageBox.prompt(message, title || '请输入', {inputValue: v, lockScroll: false}).then(({value}) => {
                let v = _.trim(value);
                if (v !== '')
                    resolve(v)
            });
        })
    };

    Vue.prototype.$message.done = function () {
        return ElementUI.Message.success('操作完毕');
    };

    Vue.prototype.$all = {current: 1, size: 100000};

    Vue.prototype.$page = function (vm, c = 1, z = 10) {
        return {
            current: _.toNumber(vm.$route.query.current) || c,
            size: _.toNumber(vm.$route.query.size) || z,
        }
    };

    initFilters(Vue);

    // 注册组件加载器
    window.$loader = function (file, name) {
        httpVueLoader.header = {
            name: config.AUTH_TOKEN,
            value: AuthToken.get()
        };

        let url = config.API_URL + '/udc/script/file?path=' + file + '.vue';
        return httpVueLoader(url, name)
    };

    // 防抖动
    const on = Vue.prototype.$on;
    const shakeTime = 500; //防抖动时间
    Vue.prototype.$on = function (event, func) {
        let previous = -1;
        let newFunc = func;
        if (event === 'click') {
            newFunc = function () {
                const now = new Date().getTime();
                if (previous + shakeTime <= now) {
                    func.apply(this, arguments);
                    previous = now
                } else {
                    console.warn('操作过快')
                }
            }
        }
        on.call(this, event, newFunc)
    }
}
