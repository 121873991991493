<!--@author xuqinjie-->
<!--@date 2018/2/12-->

<template>
    <el-dialog class="el-dialog-full"
               title="会话已过期"
               :lock-scroll="false"
               :visible.sync="visible"
               :fullscreen="true"
               :center="true"
               :show-close="false"
               :close-on-press-escape="false"
               :close-on-click-modal="false">
        <div class="full sys-lock">
            <el-card>
                <div class="sys-lock-name">
                    <h2>{{userName}}</h2>
                </div>

                <el-form :model="form" ref="form" :show-message="false">
                    <el-form-item prop="password" required>
                        <el-input v-model="form.password" type="password" prefix-icon="el-icon-lock" placeholder="请输入登录密码解锁屏幕"/>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" style="width:100%;" @click="handleLogin">登录</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </div>
    </el-dialog>
</template>

<script>
    import {userLogin} from "../api";

    export default {
        data() {
            return {
                visible: true,
                form: {
                    password: '',
                }
            }
        },
        computed: {
            user() {
                return this.$store.state.app.user;
            },
            userName() {
                if (!_.isNil(this.user))
                    return this.user.name;
                return null;
            }
        },
        methods: {
            handleLogin() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        userLogin({loginName: this.user.loginName, password: this.form.password}).then(map => {
                            this.$store.commit('setUser', map.user);
                            this.$store.commit('setExpired', false);
                            this.$router.go(0);
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sys-lock {
        display: flex;
        justify-content: center;
        align-items: center;

        .el-card {
            width: 50%;
            max-width: 480px;
        }

        &-name {
            text-align: center;
        }
    }
</style>
