import Vue from 'vue'
import Element from 'element-ui';
import router from './common/core/router'
import store from "./common/core/store";
import Interceptor from "./sys/interceptor";
import App from './app.vue'
import _ from 'lodash'
import resize from './common/core/resize'
import FullDialog from './comp/el-fulldialog'
import init from './common/core/init'

Vue.config.productionTip = false;
Vue.use(Element, {size: 'small', zIndex: 3000});
Vue.component('el-fulldialog', FullDialog);
Vue.directive('resize', resize);

new Interceptor(router, store).init();
init(store);

new Vue({
    router,
    store,
    render: h => h(App),
    data: {hub: new Vue()}
}).$mount('#app');

Vue.prototype._ = _;
