import Vue from 'vue'
import Vuex from 'vuex'
import {AuthToken, LocalStorage} from "../util/storage";
import $router from './router'

Vue.use(Vuex);

function toBool(r) {
    if (r === null || r === undefined)
        return true;
    return r >= 1 || r === 'true' || r === 'TRUE' || r === true;
}

const app = {
    state: {
        token: AuthToken.get(),
        user: null,
        innerHeight: window.innerHeight,
        loading: 0,
        showLeftMenu: toBool(LocalStorage.get('showLeftMenu')),
        expired: false,
        permissions: [],
        admin: false,
        onlineCount: null,
        appName: '',
        licenseInvalidAt: null
    },
    mutations: {
        setToken: (state, token) => {
            if (token !== state.token) {
                state.token = token;
                if (_.isNil(token))
                    AuthToken.remove();
                else
                    AuthToken.set(token);
            }
        },
        setUser: (state, v) => {
            state.user = v;
        },
        innerHeight: (state, v) => {
            state.innerHeight = v;
        },
        loading: (state, loading) => {
            if (loading)
                state.loading++;
            else
                state.loading--;

            if (state.loading < 0)
                state.loading = 0;
        },
        showLeftMenu: (state, v) => {
            state.showLeftMenu = v;
            LocalStorage.set('showLeftMenu', v);
        },
        setExpired: (state, val) => {
            state.expired = val;
        },
        setPermissions: (state, val) => {
            state.permissions = val;
        },
        setAdmin: (state, val) => {
            state.admin = val;
        },
        setOnlineCount: (state, val) => {
            state.onlineCount = val;
        },
        setAppName: (state, val) => {
            if (!_.isNil(val)) {
                state.appName = val;
                document.title = val;
            }
        },
        setLicenseInvalidAt: (state, val) => {
            state.licenseInvalidAt = val;
        },
    },
    actions: {
        updateSession({commit}, map) {
            commit('setUser', map.user);
            commit('setPermissions', map.permissions);
            commit('setAdmin', map.admin);
            commit('setExpired', false);
        },
        toLoginPage({commit}) {
            $router.replace({path: '/login?return=' + $router.currentRoute.fullPath});
        }
    }
};

export default new Vuex.Store({
    modules: {
        app
    }
})
