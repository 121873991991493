import {post} from '@/common/util/request';
import Tools from '@/common/util/tools'

//session ---------------------------------------------------
export function userSession() {
    return post('/sys/user/session');
}

export function userLogin({loginName, password}) {
    return post('/sys/user/login', {loginName, password});
}

export function userLogout() {
    return post('/sys/user/logout');
}

export function getSystemInfo() {
    return post('/sys/metrics/info');
}

//admin ---------------------------------------------------
export function kickOutUser(id) {
    return post('/sys/admin/kick-out-user', {id});
}

export function kickOutSession(id) {
    return post('/sys/admin/kick-out-id', {id});
}

export function listOnlineUsers() {
    return post('/sys/admin/online');
}

//user ---------------------------------------------------
export function listUsers({current, size}, query = {}) {
    return post('/sys/user/list', {current, size, '#query': JSON.stringify(query)});
}

function formatUserObject(row) {
    if (row.scopes) row.scopes = JSON.stringify(row.scopes);
    if (row.roles) row.roles = JSON.stringify(row.roles);
}

export function createUser(row) {
    formatUserObject(row);
    return post('/sys/user/create', row);
}

export function updateUser(row) {
    formatUserObject(row);
    return post('/sys/user/update', row);
}

export function deleteUser(id) {
    return post('/sys/user/delete', {id});
}

export function userProfile() {
    return post('/sys/user/profile');
}

export function saveProfile({mobile, email}) {
    return post('/sys/user/save-profile', {mobile, email});
}

export function savePassword({oldPassword, newPassword}) {
    return post('/sys/user/save-password', {oldPassword, newPassword});
}

export function getOnlineUsersCount() {
    return post('/sys/user/count');
}

//group ---------------------------------------------------
export function listGroups() {
    return post('/sys/group/list');
}

export function treeGroups() {
    return listGroups().then(map => Promise.resolve(map.rows))
        .then(rows => Promise.resolve(Tools.clearTreeSelectNodes(Tools.buildTree(rows, "id", "pid", "name"))))
}

export function createGroup(row) {
    return post('/sys/group/create', row);
}

export function updateGroup(row) {
    return post('/sys/group/update', row);
}

export function deleteGroup(id) {
    return post('/sys/group/delete', {id});
}

//role ---------------------------------------------------
export function listRoles({current, size}, query = {}) {
    return post('/sys/role/list', {current, size, '#query': JSON.stringify(query)});
}

export function createRole(row) {
    if (row.permissions) row.permissions = JSON.stringify(row.permissions);
    return post('/sys/role/create', row);
}

export function updateRole(row) {
    if (row.permissions) row.permissions = JSON.stringify(row.permissions);
    return post('/sys/role/update', row);
}

export function deleteRole(id) {
    return post('/sys/role/delete', {id});
}

//permission ---------------------------------------------------
export function listPermissions() {
    return post('/sys/permission/list');
}

function removeTreeEmptyNode(nodes) {
    for (let i = nodes.length - 1; i >= 0; i--) {
        let node = nodes[i];
        if (!_.isEmpty(node.children))
            removeTreeEmptyNode(node.children);
        if ((node.raw.type === 'sub' || node.raw.type === 'module') && _.isEmpty(node.children))
            nodes.splice(i, 1);
    }
    return nodes;
}

export function treePermissions() {
    return listPermissions().then(map => Promise.resolve(map.rows))
        .then(rows => {
            let nodes = Tools.clearTreeSelectNodes(Tools.buildTree(rows, "id", "pid", "name"));
            nodes = removeTreeEmptyNode(nodes);
            return Promise.resolve(nodes);
        })
}

export function createPermission(row) {
    return post('/sys/permission/create', row);
}

export function updatePermission(row) {
    return post('/sys/permission/update', row);
}

export function deletePermission(id) {
    return post('/sys/permission/delete', {id});
}

//registry ---------------------------------------------------
export function listRegistries({current, size}, query = {}) {
    return post('/sys/registry/list', {current, size, '#query': JSON.stringify(query)});
}

export function createRegistry(row) {
    return post('/sys/registry/create', row);
}

export function updateRegistry(row) {
    return post('/sys/registry/update', row);
}

export function deleteRegistry(id) {
    return post('/sys/registry/delete', {id});
}

export function getRegistry(path) {
    return post('/sys/registry/get', {path}).then(map => {
        if (!_.isNil(map.row))
            return Promise.resolve(map.row);
        else
            return Promise.reject();
    })
}

export function saveRegistry(path, props) {
    return post('/sys/registry/save', {path, props: JSON.stringify(props)});
}

export function saveRegistryRaw(path, text) {
    return post('/sys/registry/save', {path, props: text});
}

//log
export function listCallLog({current, size}, query = {}) {
    return post('/sys/log/call', {current, size, '#query': JSON.stringify(query)});
}

export function applyLogLevel(pack, level) {
    return post('/sys/log/level', {pack, level});
}

export function tailSystemLog(lines = 100) {
    return post('/sys/log/system', {lines});
}

