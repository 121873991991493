/**
 * @author xuqinjie
 * @since 2018/2/12
 */

export default {
	bind(el, {value, arg, modifiers}) {
		el.resizeCallback = _.throttle(() => value(), 150);
		window.addEventListener('resize', el.resizeCallback);
	},

	unbind(el, {value, arg, modifiers}) {
		window.removeEventListener('resize', el.resizeCallback);
	},

	inserted(el) {
		if (!_.isNil(el.resizeCallback))
			el.resizeCallback();
	}
};
