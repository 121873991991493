<template>
    <div id="app" class="full" v-cloak v-loading.lock="loading">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"/>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"/>

        <lock v-if="expired"/>

        <div class="sys-expire" v-if="showExpire && !$permit('super')">
            <h1>授权已过期!</h1>
        </div>
    </div>
</template>

<script>
    import Lock from './sys/view/lock'
    import {getOnlineUsersCount, getRegistry} from "./sys/api";

    export default {
        components: {Lock},
        computed: {
            loading() {
                return this.$store.state.app.loading > 0;
            },
            expired() {
                return this.$store.state.app.expired;
            }
        },
        data() {
            return {
                showExpire: false,
                expireAt: null
            }
        },
        created() {
            getOnlineUsersCount().then(map => this.$store.commit('setOnlineCount', map.row));

            getRegistry('sys:expire:at').then(data => {
                this.$store.commit('setLicenseInvalidAt', data);
                const now = new Date();
                const expireAt = new Date(data);
                if (now.getTime() > expireAt.getTime())
                    this.showExpire = true;
            }).catch(() => {
            });

            getRegistry('sys:app:name').then(data => this.$store.commit('setAppName', data));
        }
    }
</script>

<style lang="scss" type="text/scss">
    @import "./style/style";
    @import "./style/vue-treeselect.min.css";

    .sys-expire {
        z-index: 9999999;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: white;
        opacity: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>

