import Vue from 'vue'
import VueRouter from 'vue-router'
import sys from '../../sys/router.js'
import form from '../../form/router.js'
import gcs from '../../gcs/router.js'
import mms from '../../mms/router.js'
import media from '../../media/router.js'
import hq from '../../hq/router.js'

//禁用vue router重复报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter);

export default new VueRouter({
    routes: [
        ...sys,
        ...form,
        ...mms,
        ...gcs,
        ...media,
        ...hq
    ]
});
