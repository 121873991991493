export default [
    {
        path: '/',
        meta: {acl: 'disabled'},
        redirect: '/sys/profile',
    },
    {
        path: '/sys',
        component: () => import('./index.vue'),
        redirect: '/sys/profile',
        children: [
            {
                path: 'home',
                meta: {title: '系统首页'},
                component: () => import('./view/home.vue'),
            },
            {
                path: 'user',
                meta: {title: '用户'},
                component: () => import('./view/user/list.vue'),
            },
            {
                path: 'group',
                meta: {title: '组织'},
                component: () => import('./view/group/list.vue'),
            },
            {
                path: 'role',
                meta: {title: '角色'},
                component: () => import('./view/role/list.vue'),
            },
            {
                path: 'permission',
                meta: {title: '权限'},
                component: () => import('./view/permission/list.vue'),
            },
            {
                path: 'registry',
                meta: {title: '配置'},
                component: () => import('./view/registry/list.vue'),
            },
            {
                path: 'profile',
                meta: {title: '资料'},
                component: () => import('./view/user/profile.vue'),
            },
            {
                path: 'session',
                meta: {title: '会话'},
                component: () => import('./view/session/list.vue'),
            },
            {
                path: 'log',
                meta: {title: '日志'},
                component: () => import('./view/log/index.vue'),
                redirect: '/sys/log/call',
                children: [
                    {
                        path: 'call',
                        meta: {title: '调用日志'},
                        component: () => import('./view/log/call.vue'),
                    },
                    {
                        path: 'system',
                        meta: {title: '系统日志'},
                        component: () => import('./view/log/system.vue'),
                    }
                ]
            }
        ]
    },
    {
        path: '/login',
        meta: {acl: 'disabled'},
        component: () => import('./view/login.vue'),
    },
    {
        path: '/401',
        meta: {acl: 'disabled'},
        component: () => import('./401.vue'),
    },
    {
        path: '/404',
        meta: {acl: 'disabled'},
        component: () => import('./404.vue'),
    },
]
