export default [
    {
        path: '/form',
        component: () => import('./index.vue'),
        children: [
            {
                path: 'build',
                meta: {title: '表单构建', keepAlive: true, permit: 'super'},
                component: () => import('./build.vue'),
            }
        ]
    }
]
