<!--@author xuqinjie-->
<!--@date 2018/2/12-->

<template>
    <el-dialog
            :lock-scroll="false"
            :title="title"
            :visible.sync="visible"
            :fullscreen="full"
            :center="true"
            :append-to-body="true"
            :show-close="showClose"
            :class="this.full?'el-dialog-full':''"
            :width="this.full?'':width"
            :destroy-on-close="destroyOnClose"
            @close="$emit('close')"
            @open="$emit('open')"
            :close-on-press-escape="showClose"
            :close-on-click-modal="false">
        <div :style="{'width':this.full?width:'', 'margin':'auto'}">
            <slot/>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        props: {
            title: {
                String,
                default: '对话框'
            },
            width: {
                String,
                default: '480px'
            },
            showClose: {
                Boolean,
                default: true
            },
            full: {
                Boolean,
                default: false
            },
            destroyOnClose: {
                Boolean,
                default: false
            }
        },
        data() {
            return {
                visible: false,
            }
        },
        methods: {
            show() {
                this.visible = true;
            },
            hide() {
                this.visible = false;
            }
        }
    }
</script>
