import axios from 'axios'
import qs from 'qs'
import {Message} from 'element-ui'
import {AuthToken} from './storage'
import $store from '../core/store'
import config from "../../config";

const X_AUTH_TOKEN = config.AUTH_TOKEN;

function toast(text) {
    Message({message: text, type: 'error', duration: 5 * 1000, showClose: true});
}

const request = axios.create({
    baseURL: config.API_URL,
    timeout: 15000,
    transformRequest: [function (data) {
        if (!(data instanceof FormData))
            data = qs.stringify(data, {arrayFormat: 'repeat'});
        return data;
    }],
});

request.interceptors.request.use(config => {
    if (config.url.startsWith('http')) {
        config.baseURL = null;
    }

    $store.commit('loading', true);
    config.headers[X_AUTH_TOKEN] = AuthToken.get();
    return config;
}, error => {
    return Promise.reject(error)
});

function applyToken(response) {
    let token = response.headers[X_AUTH_TOKEN];
    if (!_.isNil(token)) {
        $store.commit('setToken', token)
    }
}

request.interceptors.response.use(response => {
    applyToken(response);

    $store.commit('loading', false);
    return response;
}, err => {
    $store.commit('loading', false);
    let res = err.response;
    if (_.isNil(res)) {
        toast('网络错误:' + err);
    } else {
        applyToken(res);
        let path = res.config.url;
        if (res.status === 401) {
            toast('权限不足，无法执行此操作:' + path);
        } else if (res.status === 440) {
            $store.commit('setExpired', true);
            toast('会话过期, 请重新登录!');
        } else if (res.status === 403) {
            toast('请登录系统!');
            $store.dispatch('toLoginPage');
        } else if (res.status === 404) {
            toast('请求的端点或文件不存在:' + path);
        } else if (res.status === 500) {
            toast('操作失败! ' + res.data);
        } else {
            console.error(err);
            toast('未知错误');
        }
    }

    return Promise.reject(err)
});

function make(method, path, data, responseType = null) {
    let cfg = {};
    if (method === 'get') {
        cfg = {url: path, method: 'get', params: {...data}}
    } else if (method === 'post') {
        if (data instanceof FormData) {
            cfg = {url: path, method: 'post', data, headers: {'Content-Type': 'multipart/form-data'}}
        } else {
            cfg = {url: path, method: 'post', data: {...data}}
        }
    }

    if (responseType !== null) cfg.responseType = responseType;

    return request(cfg).then(res => {
        if (res.status !== 200) {
            toast('请求失败');
            return Promise.reject();
        } else {
            if (_.isObject(res.data.map)) {
                if (res.data.code !== 0) {
                    toast((_.isNil(res.data.messages) ? null : res.data.messages[0]));
                    return Promise.reject();
                } else {
                    let map = res.data.map;
                    let page = map.page;
                    if (!_.isNil(page)) {
                        map.rows = page.records;
                        map.page = {current: page.current, size: page.size, total: page.total}
                    }
                    return Promise.resolve(map);

                }
            } else {
                return Promise.resolve(res.data);
            }
        }
    }).catch(err => {
        return Promise.reject(err);
    })
}

export function get(path, data) {
    return make('get', path, data);
}

export function post(path, data) {
    return make('post', path, data);
}

export function requestImage(path, data) {
    return make('get', path, data, 'blob').then(({res}) => {
        let reader = new window.FileReader();
        reader.readAsDataURL(res.data);
        reader.onload = function () {
            return Promise.resolve(reader.result);
        }
    }).catch(err => {
        return Promise.reject(err);
    })
}

// 格式化对象的key，每个key前面增加#，#在框架中表示queryMap
function toNumber(obj) {
    const v = _.toNumber(obj.value);
    if (!_.isNaN(v))
        obj.value = v;
}

function buildQuery(arr) {
    if (_.isEmpty(arr))
        return null;
    if (_.isArray(arr)) {
        _.each(arr, row => toNumber(row));
        return JSON.stringify(_.filter(arr, row => !row.column.startsWith('$')));
    } else {
        toNumber(arr);
        return arr.column.startsWith('$') ? null : JSON.stringify(arr);
    }
}

function buildProps(arr) {
    if (_.isEmpty(arr))
        return null;
    if (_.isArray(arr))
        return JSON.stringify(_.filter(arr, row => row.column.startsWith('$')));
    else
        return arr.column.startsWith('$') ? JSON.stringify(arr) : null;
}

export {request, toast, buildQuery, buildProps};
