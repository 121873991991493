import _ from 'lodash'

let config = {
    AUTH_TOKEN: 'x-auth-token',
    API_URL: 'http://47.103.21.100:8083/',
    DOG_URL: 'http://47.103.21.100:8081/',
    COPYRIGHT: '版权所有&copy;2020',
    MEDIA_HOST: 'http://qtupyun.lvluohw.com',

    mms: {
        INBOUND_REPORT: 'mms/report/inbound',
        OUTBOUND_REPORT: 'mms/report/outbound',
    }
};

if (window.XLIB_CONFIG !== undefined) {
    config = _.merge(config, window.XLIB_CONFIG);
}
export default config;
